import React, { useContext, useEffect, useState } from "react"

import axios from "axios"
//import {Tabs, Tab} from 'react-bootstrap-tabs';
import Nav from "react-bootstrap/Nav"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import Modal from "react-bootstrap/Modal"

import "./shared-artifact.css"
import { isBrowser } from "../services/developer_mode"

import { SET_NOTIFICATION_DATA } from "../stores/global_store/GlobalActions"
import { GlobalContext } from "../stores/global_store/GlobalStore"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

const SharedArtifact = (props) => {
  const trainingId = props.trainingId || 0
  const [artifactDetails, setArtifactDetails] = useState({})
  const [students, setStudents] = useState(1)
  const [loading, setLoading] = useState(true)
  //const [linkType, setLinkType] = useState(false)
  const [createUpdate, setCreateUpdate] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [globalStore, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (trainingId > 0 && props.show === true) {
      createArtifact()
    }
  }, [trainingId, props.show])

  /*useEffect(() => {
      if (students > 0) {
        updateArtifact()
      }
    }, [students])*/

  function createArtifact() {
    let f = new FormData()
    f.append("training", trainingId)

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/artifact_links/", f, { headers: login_credentials })
      .then((res) => {
        setArtifactDetails(res.data)
        setLoading(false)
        //console.log('getStudentsList Success', s)
      })
      .catch((error) => {
        console.log("createArtifact Error", error)
      })
  }

  function updateArtifact(pStudents) {
    let f = new FormData()

    //console.log(pStudents ?? students, ' ', artifactDetails)
    f.append("max_allowed_users", pStudents ?? students)

    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + "/artifact_links/" + artifactDetails.id + "/", f, { headers: login_credentials })
      .then((res) => {
        setArtifactDetails(res.data)
        //setLinkType(true)
        //console.log('getStudentsList Success', s)
      })
      .catch((error) => {
        console.log("updateArtifact Error", error)
      })
  }
  function createLink() {
    let f = new FormData()
    f.append("max_allowed_users", students)

    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + "/artifact_links/" + artifactDetails.id + "/", f, { headers: login_credentials })
      .then((res) => {
        setArtifactDetails(res.data)
        setCreateUpdate(false)
        //setLinkType(true)
        //console.log('getStudentsList Success', s)
      })
      .catch((error) => {
        console.log("updateArtifact Error", error)
      })
  }
  //const delayedSaveInput = useCallback(_.debounce( (e, value, func) => { saveInput(e, value, func) }, 1000), [])
  function saveInput(e, val, func) {
    if (val === "") {
      return
    }
    val = parseInt(val)
    if (val <= 0) {
      val = 0
    }
    if (val >= 200) {
      val = 200
    }
    func(val)
  }

  async function copyTextToClipboard(id, text) {
    let but = document.getElementById(id)
    but.innerHTML = "Copied"
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text)
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "success", title: "Successfully copied to clipboard" } })
    } else {
      document.execCommand("copy", true, text)
    }
    setTimeout(() => {
      but.innerHTML = "Copy Link"
    }, 1500)
  }

  function onTabSelected(index) {
    //if (index >= 1) return;
    let genLink = document.getElementById("genericLink"),
      resLink = document.getElementById("restrictedLink")

    if (index === "genericLink") {
      setSelectedIndex(1)
      genLink.style.display = "block"
      resLink.style.display = "none"
      //We only want "Generic Link" which is 0th index
      updateArtifact(0)
    } else {
      setSelectedIndex(2)

      genLink.style.display = "none"
      resLink.style.display = "block"
    }
  }

  let styles = { backgroundColor: "#212C41", borderRadius: "8px", border: "1px solid #fff", color: "#949AA3" },
    classes = "pl-2 pr-2 pt-3 pb-3",
    title = artifactDetails && Object.keys(artifactDetails).length > 0 ? artifactDetails.artifact_title : "Loading...",
    url = artifactDetails && Object.keys(artifactDetails).length > 0 ? artifactDetails.source_url : "Loading...",
    dispUrl = url.length > 64 ? url.substr(0, 61) + "..." : url

  return (
    <>
      <Modal
        size={"lg"}
        show={props.show}
        onHide={() => {
          props.hide()
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        centered={true}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-centered modal-wh"
      >
        <Modal.Body className={classes} style={styles}>
          <div
            onClick={() => {
              props.hide()
            }}
            className={`position-absolute pointer`}
            style={{
              color: "#2A2F34",
              textAlign: "center",
              lineHeight: "0.8",
              fontWeight: "bold",
              borderRadius: "50%",
              width: 20,
              height: 20,
              backgroundColor: "#fff",
              fontSize: "20px",
              top: "10px",
              right: "10px",
              zIndex: "9",
            }}
          >
            &times;
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className={""}>
                <FontAwesomeIcon
                  className="m-0 ml-3"
                  icon={faLink}
                  color="#aaa"
                  style={{ background: "#2C374F", width: 35, height: 35, borderRadius: "50%", padding: 5, objectFit: "contain", opacity: "0.54" }}
                />
                <span className={"pl-1"} style={{ marginTop: "0" }}>
                  Share Link for {props.resourceType}
                </span>
                <div className={"ml-1 mb-3 pl-3"} style={{ color: "#fff", fontWeight: "bold" }}>
                  {title}
                </div>
              </div>
              <Nav
                className={"ml-3"}
                style={{ borderBottom: "1px solid white" }}
                activeEventKey="genericLink"
                onSelect={(selectedKey) => onTabSelected(selectedKey)}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="genericLink"
                    style={{
                      color: `${selectedIndex === 1 ? "#fff" : "#aaa"}`,
                      fontWeight: `${selectedIndex === 1 ? "bold" : "normal"}`,
                      border: 0,
                      paddingLeft: 0,
                    }}
                  >
                    Generic Link
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="restrictedLink"
                    style={{
                      color: `${selectedIndex === 2 ? "#fff" : "#aaa"}`,
                      fontWeight: `${selectedIndex === 2 ? "bold" : "normal"}`,
                      border: 0,
                      paddingLeft: 0,
                    }}
                  >
                    Restricted Link
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div id="genericLink" style={{ display: `${selectedIndex === 1 ? "block" : "none"}`, marginBottom: 60 }}>
                <div className="mt-3 font-12 pl-4">Anyone can join using this link</div>
                <div className={"d-inline-flex w-100 mb-2 pl-4"}>
                  <div className={"d-inline-flex mr-2 p-2"} style={{ background: "#42506C", width: "80%", borderRadius: 6 }}>
                    {dispUrl}
                  </div>
                  <button
                    id="genericBut"
                    style={{
                      background: "#586886",
                      border: "1px solid #fff",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: 6,
                      color: "#fff",
                      padding: "5px 20px",
                    }}
                    onClick={() => copyTextToClipboard("genericBut", url)}
                  >
                    Copy Link
                  </button>
                </div>
                {/*<div>
            <div className="font-12 pl-4">&nbsp;</div>
            <div className={"d-inline-flex w-100 mb-2 pl-4"}>
                <div className={"d-inline-flex mr-2 p-2"}>&nbsp;</div>
            </div>
            </div>
            */}
              </div>
              <div id="restrictedLink" style={{ display: `${selectedIndex === 2 ? "block" : "none"}` }}>
                <div className="mt-3 font-12 pl-4">&nbsp;</div>
                <div className={"d-inline-flex w-100 mb-2 pl-4"}>
                  <div className={"d-inline-flex p-2"} style={{ background: "#42506C", width: "65%", borderRadius: 3 }}>
                    Number of participants you want to generate the link for
                  </div>
                  <input
                    id={`et`}
                    key={`et`}
                    type="number"
                    min="0"
                    max="200"
                    maxLength={3}
                    className={"p-2 mr-2"}
                    placeholder={"Anyone"}
                    defaultValue={students}
                    onChange={(e) => {
                      saveInput(e, e.target.value, setStudents)
                    }}
                    onBlur={(e) => {
                      saveInput(e, e.target.value, setStudents)
                    }}
                    style={{ border: "none", background: "#56637C", width: "15%", color: "#fff", borderRadius: 0 }}
                  />
                  <button
                    style={{ background: "#586886", border: "none", boxShadow: "0px 3px 6px #00000029", borderRadius: 6, color: "#fff", padding: "5px 20px" }}
                    onClick={(e) => {
                      createUpdate ? createLink() : updateArtifact()
                    }}
                  >
                    {createUpdate ? "Create" : "Update"}
                  </button>
                </div>
                {createUpdate ? null : (
                  <div>
                    <div className="font-12 pl-4">Link create for {students} participants</div>
                    <div className={"d-inline-flex w-100 mb-2 pl-4"}>
                      <div className={"d-inline-flex mr-2 p-2"} style={{ background: "#42506C", width: "80%", borderRadius: 6 }}>
                        {dispUrl}
                      </div>
                      <button
                        id="restrictedBut"
                        style={{
                          background: "#586886",
                          border: "1px solid #fff",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: 6,
                          color: "#fff",
                          padding: "5px 20px",
                        }}
                        onClick={() => copyTextToClipboard("restrictedBut", url)}
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SharedArtifact
/**
 *           
 <div className={"d-inline-flex w-100 mb-2 pl-5"} >
              <div className={"d-inline-flex p-2"} style={{background: '#42506C', width: '65%', borderRadius: 3}}>Number of participants you want to this link to open for</div>
              <input id={`et`} key={`et`} type="number" min="1" max="99" 
              className={"p-2 mr-2"}
              placeholder={"Anyone"}
              //value={"5"}
              onChange={(e) => {delayedSaveInput(e.target.value, setStudents)}}
              onBlur={(e) => {delayedSaveInput(e.target.value, setStudents)}}
              style={{border: 'none', background: '#56637C', width: '15%', color: '#fff', borderRadius: 0}}
              />
              <button style={{background: '#586886', border: 'none', boxShadow: '0px 3px 6px #00000029', borderRadius: 6, color: '#fff', padding: '5px 20px'}} onClick={(e) => {updateArtifact()}}>Update</button>
  
          </div>
          <div className="font-12 pl-5">
            {linkType
            ? <><b style={{color: '#fff'}}>Restricted Link :</b> {students} participants can join this link</>
            : <><b style={{color: '#fff'}}>Generic Link :</b> Anyone can join using this link</>
            }
          </div>
          <div className={"d-inline-flex w-100 mb-2 pl-5"}>
              <div className={"d-inline-flex mr-2 p-2"} style={{background: '#42506C', width: '80%', borderRadius: 6}}>{url}</div>
              <button style={{background: '#586886', border: '1px solid #fff', boxShadow: '0px 3px 6px #00000029', borderRadius: 6, color: '#fff', padding: '5px 20px'}} onClick={() => copyTextToClipboard(url)}>Copy</button>
          </div>


          {/*
          <Tabs className='pl-0' style={{}} headerStyle={{color: '#aaa', border: 0, paddingLeft: 0}} activeHeaderStyle={{color:'#fff', fontWeight: 'bold', background: 'none'}} contentStyle={{marginBottom: 0}}
          onSelect={(index, label) => onTabSelected(index)}>
            <Tab label="Generic Link" >
              <div className="mt-3 font-12 pl-4">Anyone can join using this link</div>
              <div className={"d-inline-flex w-100 mb-2 pl-4"} style={{marginBottom: 100}}>
                  <div className={"d-inline-flex mr-2 p-2"} style={{background: '#42506C', width: '80%', borderRadius: 6}}>{dispUrl}</div>
                  <button style={{background: '#586886', border: '1px solid #fff', boxShadow: '0px 3px 6px #00000029', borderRadius: 6, color: '#fff', padding: '5px 20px'}} onClick={() => copyTextToClipboard(url)}>Copy Link</button>
              </div>
            </Tab>
            <Tab label="Restricted Link">
            <div className={"mt-3 d-inline-flex w-100 mb-2 pl-4"} >
              <div className={"d-inline-flex p-2"} style={{background: '#42506C', width: '65%', borderRadius: 3}}>Number of participants you want to generate the link for</div>
              <input id={`et`} key={`et`} type="number" min="0" max="200" maxLength={3}
              className={"p-2 mr-2"}
              placeholder={"Anyone"}
              defaultValue={students}
              onChange={(e) => {saveInput(e, e.target.value, setStudents)}}
              onBlur={(e) => {saveInput(e, e.target.value, setStudents)}}
              style={{border: 'none', background: '#56637C', width: '15%', color: '#fff', borderRadius: 0}}
              />
              <button style={{background: '#586886', border: 'none', boxShadow: '0px 3px 6px #00000029', borderRadius: 6, color: '#fff', padding: '5px 20px'}} onClick={(e) => {createUpdate ? createLink() : updateArtifact()}}>{createUpdate ? 'Create' : 'Update'}</button> 
            </div>
            {createUpdate
            ? null
            :
            <div>
            <div className="font-12 pl-4">Link create for {students} participants</div>
            <div className={"d-inline-flex w-100 mb-2 pl-4"}>
                <div className={"d-inline-flex mr-2 p-2"} style={{background: '#42506C', width: '80%', borderRadius: 6}}>{dispUrl}</div>
                <button style={{background: '#586886', border: '1px solid #fff', boxShadow: '0px 3px 6px #00000029', borderRadius: 6, color: '#fff', padding: '5px 20px'}} onClick={() => copyTextToClipboard(url)}>Copy Link</button>
            </div>
            </div>
            }
            </Tab>
          </Tabs>
          }


 */
